import React from "react"
import { graphql } from 'gatsby'
 
import Layout from "../components/Layout"
import Seo from '../components/SEO'
import { Row}  from 'react-bootstrap'
import * as styles from '../styles/list-item.module.css'

import TimelineItem from '../components/Timeline_Item'

const TurtoialList = ({ data, pageContext }) => {

    const examples = data.allMarkdownRemark.nodes;
    
    return (
        <Layout>
      <Seo 
      title="Online Web Tutorials"
      description="Kafle.io is an one-stop place where you can learn many frameworks and programming languages such as Laravel/PHP, ASP.NET Core, NET, C#, JQuery, AJAX"
      keywords="ASP.NET, C#, Laravel, PHP, Joomla, Wordpress"
      />     
            <h1>Online Tutorials</h1>              
            <Row className={styles.list}>
                {examples.map(item => (
                <TimelineItem key={item.id}
                title={item.frontmatter.title}
                link = {"/" + item.frontmatter.slug}
                image = {"/" + item.frontmatter.image}
                published= {item.frontmatter.published}

                />           
            ))}
            </Row>
        </Layout>
    )
}


export const query= graphql`
query($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
        filter: {frontmatter: {featured: {eq: 1}}}
        sort: {fields: frontmatter___published, order: DESC}
        skip: $skip,
        limit: $limit
        ) {
        nodes {
            frontmatter {
                slug
                title
                image
                published(formatString: "MMMM Do, YYYY")
            }
            id
        }
    }
  }

`

export default TurtoialList;